import { Tabs } from "antd";
import { OrganizationAccount } from "src/pages/profile/components/users/components/organization/OrganizationAccount";
import { OrganizationTeams } from "src/pages/profile/components/users/components/organization/OrganizationTeams";
import { OrganizationUsers } from "src/pages/profile/components/users/components/organization/OrganizationUsers";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { userPreference } from "src/routes/routes";

export const OrgAdmin = () => {
  const [t] = useTranslation(["dashboard"]);
  const navigate = useNavigate();
  const params = useParams();

  const [input, setInput] = useState({
    preference: params.preference || "preferences",
  });

  useEffect(() => {
    setInput({
      ...input,
      preference: params.preference || "preferences",
    });
  }, [params.preference]);

  const preferenceOnChange = (key) => {
    setInput({ ...input, preference: key });
    navigate(userPreference(params) + `/${key}`);
  };

  return (
    <Tabs
      activeKey={input.preference}
      onChange={preferenceOnChange}
      items={[
        {
          key: "preferences",
          label: t("preferences"),
          children: <OrganizationAccount />,
        },
        {
          key: "teams",
          label: t("teams"),
          children: <OrganizationTeams />,
        },
        {
          key: "users",
          label: t("users"),
          children: <OrganizationUsers />,
        },
      ]}
      tabPosition="left"
    />
  );
};

import { deleteAPI, getAPI, postAPI } from "src/common/api/api";

const PATH = "catman/navigation/category/group-parameter/parameter";

export const fetchParameters = async (lang) => getAPI(PATH, { Locale: lang });

export const fetchParameter = async (id) => getAPI(`${PATH}/${id}`);

export const persistParameter = async (body) => postAPI(PATH, body);

export const deleteParameter = async (id) => deleteAPI(`${PATH}/${id}`);

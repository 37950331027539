import {
  getCookie,
  hasCookieRemoved,
  persistCookie,
  removeCookie,
} from "src/common/api/cookie";

export const setAuthCookie = (token, expires) => {
  persistCookie("token", token, expires);
};

export const getAuthCookie = () => getCookie("token");

export const removeAuthCookie = () => {
  removeCookie("token");
};

export const hasAuthCookie = () => !hasCookieRemoved("token");

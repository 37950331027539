import {
  Button,
  Form,
  Input,
  notification,
  Segmented,
  Select,
  Space,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { fetchCategories } from "src/pages/catman/components/products/components/product/api/category";
import { useParams } from "react-router-dom";
import { fetchGroupParameter } from "src/pages/catman/components/products/components/product/api/groupParameter";
import { fetchParameter } from "src/pages/catman/components/products/components/product/api/parameter";
import { fetchParameterOption } from "src/pages/catman/components/products/components/product/api/parameterOptions";
import { TextParameter } from "src/pages/catman/components/products/components/product/components/TextParameter";
import { LogicalParameter } from "src/pages/catman/components/products/components/product/components/LogicalParameter";
import { NumberParameter } from "src/pages/catman/components/products/components/product/components/NumberParameter";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import {
  deleteProduct,
  fetchProducts,
  persistProduct,
} from "src/pages/catman/components/products/components/product/api/product";
import { error, success } from "src/common/message/notification";
import { persistBrand } from "src/pages/catman/components/products/components/brand/api/brand";
import { fetchTrademarks } from "src/pages/catman/components/products/components/product/api/trademark";

export const Product = () => {
  const NEW = "new";
  const EXISTING = "existing";

  const TEXT = "TEXT";
  const NUMBER = "NUMBER";
  const LOGICAL = "LOGICAL";

  const { t } = useTranslation("catman");
  const [api, contextHolder] = notification.useNotification();
  const { lang } = useParams();

  const [input, setInput] = useState({
    status: NEW,
    ean: "",
    mpn: "",
    trademarks: [],
    trademarkId: null,
    products: [],
    productId: null,
    categories: [],
    categoryId: null,
    groupParameters: [],
    groupParameterId: null,
    parameters: [],
    parameterId: null,
    parameterOptions: [],
    parameterOptionId: null,
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        trademarks:
          (await fetchTrademarks())?.map((brand) => ({
            ...brand,
            value: `${brand.value}`,
            children: brand.children.map((trademark) => ({
              ...trademark,
              value: `${brand.value}|${trademark.value}`,
            })),
          })) || [],
        products:
          (await fetchProducts(lang)).map((product) => ({
            ...product,
            value: product.productId,
            label: `${product.ean} (${product.mpn})`,
          })) || [],
        categories: (await fetchCategories(lang)) || [],
      });
    };

    init();
  }, []);

  const traverseTree = (data, source) => {
    return data.map((item) => ({
      ...item,
      disabled: item.type === source,
      children: item?.children ? traverseTree(item?.children, source) : [],
    }));
  };

  const parameterType = (parameters, parameterId) => {
    if (parameterId === null) return null;

    return parameters.find((parameter) => parameter.value === parameterId)
      ?.type;
  };

  const productStatusOnChange = (value) => {
    setInput({
      ...input,
      status: value,
      ean: "",
      mpn: "",
      trademarkId: null,
      productId: null,
      categoryId: null,
      groupParameters: [],
      groupParameterId: null,
      parameters: [],
      parameterId: null,
      parameterOptions: [],
      parameterOptionId: null,
    });
  };

  function findParentByChildId(items, childId, parent = null) {
    return items.reduce(
      (found, item) =>
        found ||
        (item.type === "CATEGORY" && item.value?.split("|")[1] === childId
          ? parent
          : item.children
            ? findParentByChildId(item.children, childId, item)
            : null),
      null,
    );
  }

  const existingProductOnChange = async (value) => {
    const product = input.products?.find(
      (product) => product.productId === value,
    );

    const parentCategory = findParentByChildId(
      input.categories,
      product?.categoryId?.toString(),
    )?.value?.split("|")[1];

    setInput({
      ...input,
      productId: value,
      categoryId: `${parentCategory}|${product?.categoryId}`,
      groupParameters: (
        (await fetchGroupParameter(product?.categoryId)) || []
      ).map((groupParameter) => ({
        value: groupParameter.id,
        label: groupParameter?.translation?.[lang],
      })),
      ean: product?.ean,
      mpn: product?.mpn,
      trademarkId: `${product?.brandId}|${product?.trademarkId}`,
    });
  };

  const categoryOnChange = async (value) => {
    setInput({
      ...input,
      categoryId: value,
      groupParameters: (
        (await fetchGroupParameter(value?.split("|")[1])) || []
      ).map((groupParameter) => ({
        value: groupParameter.id,
        label: groupParameter?.translation?.[lang],
      })),
      parameters: [],
      parameterId: null,
      parameterOptions: [],
      parameterOptionId: null,
    });
  };

  const groupParameterOnChange = async (value) => {
    setInput({
      ...input,
      parameters: ((await fetchParameter(value)) || []).map((parameter) => ({
        ...parameter,
        label: parameter.translation?.[lang],
      })),
      groupParameterId: value,
      parameterId: null,
      parameterOptions: [],
      parameterOptionId: null,
    });
  };

  const parameterOnChange = async (value) => {
    const hasOptions =
      input.parameters.find((option) => option.value === value)?.type === TEXT;

    setInput({
      ...input,
      parameterId: value,
      parameterOptions: hasOptions
        ? ((await fetchParameterOption(value.split("|")[1])) || []).map(
            (parameterOption) => ({
              ...parameterOption,
              label: parameterOption.translation?.[lang],
            }),
          )
        : [],
      parameterOptionId: null,
    });
  };

  const optionParameterOnChange = async (value) => {
    setInput({
      ...input,
      parameterOptionId: value,
    });
  };

  const existingTrademarkOnChange = (value) => {
    setInput({
      ...input,
      trademarkId: value,
    });
  };

  const eanOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      ean: value,
    });
  };

  const mpnOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      mpn: value,
    });
  };

  const deleteProductOnClick = async () => {
    if (input.productId !== null) {
      try {
        await deleteProduct(input.productId);
        success(api, t("success"), t("deleteProduct"));
      } catch (e) {
        error(api, t("error"), t("persistProduct"));
        console.log("Delete", e.message);
      }
    }

    setInput({
      ...input,
      ean: "",
      mpn: "",
      products: input.products.filter((item) => item.value !== input.productId),
      productId: null,
      categories: [],
      categoryId: null,
      groupParameters: [],
      groupParameterId: null,
      parameters: [],
      parameterId: null,
      parameterOptions: [],
      parameterOptionId: null,
    });
  };

  const saveProductOnClick = async () => {
    try {
      const request = {
        id: input.productId,
        product_id: input.productId,
        category_id: input.categoryId?.split("|")[1],
        group_parameter_id: input.groupParameterId,
        parameter_id: input.parameterId?.split("|")[1],
        parameter_option_id: input.parameterOptionId?.split("|")[1],
        brand_id: input.trademarkId?.split("|")[0],
        trademark_id: input.trademarkId?.split("|")[1],
        mpn: input.mpn,
        ean: input.ean,
      };
      const response = await persistProduct(request);
      const label = `${response.ean} (${response.mpn})`;
      success(api, t("success"), t("persistProduct"));
      setInput({
        ...input,
        products:
          input.status === NEW
            ? [...input.products, { value: response?.productId, label: label }]
            : input.products.map((item) =>
                item.value === input.productId
                  ? { ...item, value: response?.productId, label: label }
                  : item,
              ),
        productId: response?.productId,
      });
    } catch (e) {
      error(api, t("error"), t("persistBrand"));
      console.log("Persist", e.message);
    }
  };

  return (
    <Form layout="vertical">
      {contextHolder}
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Segmented
            value={input.status}
            options={[
              {
                value: NEW,
                label: t(NEW),
              },
              {
                value: EXISTING,
                label: t(EXISTING),
              },
            ]}
            onChange={productStatusOnChange}
          />
          {input.status === EXISTING && (
            <Select
              placeholder={t("existingProduct")}
              value={input.productId}
              onChange={existingProductOnChange}
              options={input.products}
              showSearch
              optionFilterProp="label"
              virtual={true}
              style={{ width: "100%" }}
            />
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("existingTrademark")}>
        <TreeSelect
          placeholder={t("existingTrademark")}
          value={input.trademarkId}
          onChange={existingTrademarkOnChange}
          treeData={input.trademarks.map((brand) => ({
            ...brand,
            disabled: true,
          }))}
          showSearch
          allowClear
          treeDefaultExpandAll
        />
      </Form.Item>
      <Form.Item label={t("ean")}>
        <Input
          value={input.ean}
          onChange={eanOnChange}
          placeholder={t("ean")}
          allowClear
        />
      </Form.Item>
      <Form.Item label={t("mpn")}>
        <Input
          value={input.mpn}
          onChange={mpnOnChange}
          placeholder={t("mpn")}
          allowClear
        />
      </Form.Item>
      <Form.Item label={t("categoryName")}>
        <TreeSelect
          treeData={traverseTree(input.categories, "NAVIGATION")}
          value={input.categoryId}
          onChange={categoryOnChange}
          filterTreeNode={(input, node) =>
            node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          placeholder={t("categoryName")}
          showSearch
          allowClear
          treeDefaultExpandAll
          style={{
            width: "100%",
          }}
          dropdownStyle={{
            maxHeight: 400,
            overflow: "auto",
          }}
        />
      </Form.Item>
      {input.categoryId && (
        <Form.Item label={t("groupParameters")}>
          <Select
            options={input.groupParameters}
            value={input.groupParameterId}
            placeholder={t("groupParameters")}
            onChange={groupParameterOnChange}
            showSearch
            optionFilterProp="label"
            virtual={true}
          />
        </Form.Item>
      )}
      {input.groupParameterId && (
        <Form.Item label={t("parameters")}>
          <Select
            options={input.parameters}
            value={input.parameterId}
            onChange={parameterOnChange}
            placeholder={t("parameters")}
            showSearch
            optionFilterProp="label"
            virtual={true}
          />
        </Form.Item>
      )}
      {parameterType(input.parameters, input.parameterId) === TEXT && (
        <Form.Item label={t("parameterOptions")}>
          <TextParameter
            value={input.parameterOptions.find(
              (option) => option.value === input.parameterOptionId,
            )}
            options={input.parameterOptions}
            onChange={optionParameterOnChange}
          />
        </Form.Item>
      )}
      {parameterType(input.parameters, input.parameterId) === NUMBER && (
        <NumberParameter
          value={input.parameterOptionId}
          onChange={optionParameterOnChange}
          min={
            input.parameters.find(
              (option) => option.value === input.parameterId,
            )?.min_value
          }
          max={
            input.parameters.find(
              (option) => option.value === input.parameterId,
            )?.max_value
          }
        />
      )}
      {parameterType(input.parameters, input.parameterId) === LOGICAL && (
        <LogicalParameter
          value={input.parameterOptionId}
          onChange={optionParameterOnChange}
        />
      )}
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.status === EXISTING && input.productId !== null && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={deleteProductOnClick}
            >
              {t("delete")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={saveProductOnClick}
          >
            {t("save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};

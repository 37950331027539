import {
  Button,
  Cascader,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Space,
  TreeSelect,
} from "antd";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { usePreferences } from "src/store/globalStore";
import { fetchUnits } from "src/pages/catman/components/category/components/parameter/api/units";
import { useParams } from "react-router-dom";
import { fetchParameter } from "./api/parameter";
import { fetchGroupParameters } from "./api/groupParameter";

export default function Parameter() {
  const TEXT = "TEXT";
  const NUMBER = "NUMBER";
  const LOGICAL = "LOGICAL";

  const { language } = usePreferences();
  const { lang } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [t] = useTranslation("catman");

  const [input, setInput] = useState({
    groupParameters: [],
    groupParameterId: null,
    units: [],
    addNew: TEXT,
    parameters: [],
  });

  useEffect(() => {
    const initInput = async () => {
      setInput({
        ...input,
        groupParameters: ((await fetchGroupParameters(lang)) || []).map(
          (param) => ({
            value: `${param.category_id}|${param.id}`,
            title: param?.title,
          }),
        ),
        units: ((await fetchUnits(lang)) || []).map((unit) => ({
          value: unit.id,
          label: `${unit.unit} (${unit.metric})`,
        })),
      });
    };

    initInput();
  }, []);

  const filterTreeNode = (input, node) => {
    return node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const groupParameterOnChange = async (value) => {
    if (!value) {
      setInput({
        ...input,
        groupParameterId: null,
        parameters: [],
      });
      return;
    }

    const parameters = (await fetchParameter(value?.split("|")[1])) || [];

    setInput({
      ...input,
      groupParameterId: value,
      parameters: parameters.map((parameter, i) => ({
        ...parameter,
        language: language.value || lang,
        key: i + 1,
      })),
    });
  };

  const newParameterTypeOnSelect = ({ item: { props } }) => {
    setInput({
      ...input,
      addNew: props.value,
    });
  };

  const addNewParameterOnClick = () => {
    setInput({
      ...input,
      parameters: [
        ...input.parameters,
        {
          key: Math.max(...input.parameters.map((item) => item.key), 0) + 1,
          id: null,
          order_index: null,
          type: input.addNew,
          language: language.value || lang,
          translation: {},
        },
      ],
    });
  };

  // const parameterOnUpdate = async (item) => {
  //   try {
  //     await persistParameter({
  //       group_parameter_id: input.groupParameterId?.split("|")[1],
  //       id: item.value?.split("|")[1],
  //       type: item.type,
  //       order_index: item.order_index,
  //       min_value: item.min_value,
  //       max_value: item.max_value,
  //       unit_id: item.unit_id?.[0],
  //       translation: item.translation,
  //     });
  //     success(api, t("success"), t("persistParameter"));
  //     setInput({
  //       ...input,
  //       parameters: input.parameters.map((parameter) =>
  //         parameter.key === item.key ? item : parameter,
  //       ),
  //     });
  //   } catch (e) {
  //     error(api, t("error"), t("persistParameter"));
  //     console.log("Persist", e.message);
  //   }
  // };

  // const parameterOnRemove = async (item) => {
  //   if (item.value !== null) {
  //     try {
  //       await deleteParameter(item.value.split("|")[1]);
  //       success(api, t("success"), t("deleteParameter"));
  //     } catch (e) {
  //       error(api, t("error"), t("persistParameter"));
  //       console.log("Delete", e.message);
  //     }
  //   }
  //   setInput({
  //     ...input,
  //     parameters: input.parameters.filter(
  //       (parameter) => parameter.key !== item.key,
  //     ),
  //   });
  // };

  const parameterDeleteOnClick = (key) => {
    setInput({
      ...input,
      parameters: input.parameters.filter((p) => p.key !== key),
    });
  };

  return (
    <Form layout="vertical">
      {contextHolder}
      <Form.Item label={t("parentGroupParameters")}>
        <Space.Compact style={{ width: "100%" }}>
          <TreeSelect
            treeData={input.groupParameters}
            value={input.groupParameterId}
            onChange={groupParameterOnChange}
            filterTreeNode={filterTreeNode}
            placeholder={t("parentGroupParameters")}
            showSearch
            allowClear={{
              clearIcon: <CloseOutlined onClick={groupParameterOnChange} />,
            }}
            treeDefaultExpandAll
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
          />
        </Space.Compact>
      </Form.Item>
      {input.parameters?.map((item) => (
        <div key={item.key}>
          {item.type === TEXT && (
            <>
              <Divider orientation="left" plain>
                <Space>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => parameterDeleteOnClick(item.key)}
                  />
                  {t(`textParameter`)}
                </Space>
              </Divider>
              <Form.Item label={t("parameterName")}>
                <Space.Compact style={{ width: "100%" }}>
                  <Select
                    value={input.language}
                    // onChange={languageOnSelect}
                    options={[
                      {
                        value: "de",
                        label: "🇩🇪",
                      },
                      {
                        value: "en",
                        label: "🇺🇸",
                      },
                      {
                        value: "pl",
                        label: "🇵🇱",
                      },
                      {
                        value: "ua",
                        label: "🇺🇦",
                      },
                    ]}
                    style={{ width: "auto" }}
                    virtual
                  />
                  <Input
                    value={item.translation[item.language]}
                    // onChange={nameOnInput}
                    placeholder={t("parameterName")}
                    style={{ width: "100%" }}
                  />
                </Space.Compact>
              </Form.Item>
            </>
          )}
          {item.type === NUMBER && (
            <>
              <Divider orientation="left" plain>
                <Space>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => parameterDeleteOnClick(item.key)}
                  />
                  {t(`numberParameter`)}
                </Space>
              </Divider>
              <Form.Item label={t("parameterName")}>
                <Space.Compact style={{ width: "100%" }}>
                  <Select
                    value={item.language}
                    // onChange={languageOnSelect}
                    options={[
                      {
                        value: "de",
                        label: "🇩🇪",
                      },
                      {
                        value: "en",
                        label: "🇺🇸",
                      },
                      {
                        value: "pl",
                        label: "🇵🇱",
                      },
                      {
                        value: "ua",
                        label: "🇺🇦",
                      },
                    ]}
                    style={{ width: "auto" }}
                    virtual
                  />
                  <Input
                    value={item.translation[item.language]}
                    // onChange={nameOnInput}
                    placeholder={t("parameterName")}
                    style={{ width: "100%" }}
                  />
                </Space.Compact>
              </Form.Item>
              <Form.Item label={t("measurementUnit")}>
                <Space.Compact style={{ width: "100%" }}>
                  <Cascader
                    expandTrigger="hover"
                    value={[item.unit_id]}
                    options={input.units}
                    // onChange={unitOnChange}
                    placeholder={t("measurementUnit")}
                    showSearch={{
                      filter: (inputValue, path) =>
                        path.some(
                          (option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) > -1,
                        ),
                    }}
                  />
                </Space.Compact>
              </Form.Item>
              <Form.Item label={t("validValueForParameter")}>
                <Space.Compact style={{ width: "100%" }}>
                  <InputNumber
                    value={input.min_value}
                    // onChange={minValueOnChange}
                    defaultValue={0}
                    min={0}
                    keyboard
                    changeOnWheel
                  />
                  <InputNumber
                    value={input.max_value}
                    // onChange={maxValueOnChange}
                    defaultValue={1}
                    min={0}
                    keyboard
                    changeOnWheel
                  />
                </Space.Compact>
              </Form.Item>
            </>
          )}
          {item.type === LOGICAL && (
            <>
              <Divider orientation="left" plain>
                <Space>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => parameterDeleteOnClick(item.key)}
                  />
                  {t(`logicalParameter`)}
                </Space>
              </Divider>
              <Form.Item label={t("parameterName")}>
                <Space.Compact style={{ width: "100%" }}>
                  <Select
                    value={input.language}
                    // onChange={languageOnSelect}
                    options={[
                      {
                        value: "de",
                        label: "🇩🇪",
                      },
                      {
                        value: "en",
                        label: "🇺🇸",
                      },
                      {
                        value: "pl",
                        label: "🇵🇱",
                      },
                      {
                        value: "ua",
                        label: "🇺🇦",
                      },
                    ]}
                    style={{ width: "auto" }}
                    virtual
                  />
                  <Input
                    value={item.translation[item.language]}
                    // onChange={nameOnInput}
                    placeholder={t("parameterName")}
                    style={{ width: "100%" }}
                  />
                </Space.Compact>
              </Form.Item>
            </>
          )}
        </div>
      ))}
      <Divider />
      {input.groupParameterId !== null && (
        <Form.Item>
          <Space.Compact>
            <Dropdown.Button
              menu={{
                items: [
                  {
                    value: TEXT,
                    label: t("textParameter"),
                  },
                  {
                    value: NUMBER,
                    label: t("numberParameter"),
                  },
                  {
                    value: LOGICAL,
                    label: t("logicalParameter"),
                  },
                ],
                onClick: newParameterTypeOnSelect,
              }}
              onClick={addNewParameterOnClick}
            >
              <PlusOutlined /> {t("addNew")}:{" "}
              {t(`${input?.addNew?.toLowerCase()}Parameter`)}
            </Dropdown.Button>
          </Space.Compact>
        </Form.Item>
      )}
    </Form>
  );
}

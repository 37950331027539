import { Card, Flex, Segmented } from "antd";
import React, { useState } from "react";
import { login } from "src/pages/auth/login/api/auth";
import { useTranslation } from "react-i18next";
import { Login } from "src/pages/auth/login/components/Login";
import { Register } from "src/pages/auth/login/components/Register";
import { useLocation } from "react-router-dom";

export const Authentication = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const [t] = useTranslation("dashboard");

  const [input, setInput] = useState({
    type: t("login"),
    orgInviteToken: queryParams.get("org"),
  });

  const typeOnChange = (value) => {
    setInput({ ...input, type: value });
  };

  return (
    <Flex justify="center">
      <Card style={{ minWidth: "30em" }}>
        <Segmented
          options={[t("login"), t("register")]}
          onChange={typeOnChange}
          block
          style={{ width: "100%", marginBottom: "24px" }}
        />
        {input.type === t("login") && (
          <Login orgInviteToken={input.orgInviteToken} />
        )}
        {input.type === t("register") && (
          <Register orgInviteToken={input.orgInviteToken} />
        )}
      </Card>
    </Flex>
  );
};

import { getLanguage, setLanguage } from "src/common/storage/storage";
import { getRoles } from "src/common/api/authorization";

export const preferencesActions = {
  RESET: "reset",
  LANGUAGE: "language",
  ROLES: "roles",
};

export const preferencesInitialState = {
  language: getLanguage() || "en",
  roles: getRoles(),
};

export const preferencesReducer = (state, action) => {
  switch (action.type) {
    case preferencesActions.LANGUAGE:
      setLanguage(action.language);
      return {
        ...state,
        language: action.language,
      };
    case preferencesActions.ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case preferencesActions.RESET:
      return preferencesInitialState;
    default:
      return state;
  }
};

import { combineReducers } from "src/store/reducers/utils/combineReducers";
import {
  preferencesInitialState,
  preferencesReducer,
} from "src/store/reducers/preferences";
import {
  dashboardInitialState,
  dashboardReducer,
} from "src/store/reducers/dashboard";

export const rootReducer = combineReducers({
  preferences: preferencesReducer,
  dashboard: dashboardReducer,
});

export const rootInitialState = {
  preferences: preferencesInitialState,
  dashboard: dashboardInitialState,
};

export const catmanSection = ({ lang, section }) =>
  `/${lang}/catman/${section}`;

export const catmanGroup = ({ lang, section, group }) =>
  `${catmanSection({ lang, section })}/${group}`;

export const catmanSubGroup = ({ lang, section, group, subGroup }) =>
  `${catmanGroup({ lang, section, group })}/${subGroup}`;

export const userPreferences = ({ lang }) => `/${lang}/user`;

export const userPreference = ({ lang, preferences }) =>
  `${userPreferences({ lang })}/${preferences}`;

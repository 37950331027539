import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout, { Content } from "antd/lib/layout/layout";
import {
  Button,
  Cascader,
  Checkbox,
  Collapse,
  Form,
  InputNumber,
  Space,
  Table,
} from "antd";
import {
  DeleteOutlined,
  FilterOutlined,
  FunnelPlotOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Card } from "antd/lib";
import "./Home.css";
import { useParams } from "react-router-dom";
import Sider from "antd/lib/layout/Sider";
import { fetchCategories } from "src/pages/home/api/category";
import { fetchFilters } from "src/pages/home/api/filter";
import { usePreferences } from "src/store/globalStore";
import { fetchProducts } from "src/pages/home/api/product";

export default function Home() {
  const PRODUCTS = "products";
  const CHARACTERISTICS = "characteristics";
  const ADDITIONAL_INFO = "additionalInfo";

  const [t] = useTranslation(["dashboard"]);
  const { lang } = useParams();
  const { isAuthenticated } = usePreferences();

  const [input, setInput] = useState({
    filtersCollapsed: true,
    columnsCollapsed: true,
    activeTab: PRODUCTS,
    categories: [],
    categoryId: null,
    table: {
      loading: false,
      columns: [],
      columnValues: [],
      selectedFilters: {},
      filters: [],
      filteredColumns: {},
      data: [],
      filteredData: [],
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const init = async () => {
      let filters = [];
      let products = [];

      if (input.categoryId !== null) {
        const categoryId = input.categoryId?.at(-1)?.split("|")[1];
        filters = (await fetchFilters(lang, categoryId)?.items) || [];
        products = await fetchProducts(categoryId, lang);
      }

      const categories = (await fetchCategories(lang)) || [];

      const data = tableData(products, filters);

      setInput({
        ...input,
        categories: Array.isArray(categories) ? categories : [],
        table: {
          ...input.table,
          loading: false,
          columnValues: columnParameterValues(filters),
          columns: products.length > 0 ? columns(filters) : [],
          data: data,
          filteredData: data,
          filters: filters,
        },
      });
    };

    init();
  }, [lang]);

  const chars = 15;

  const columns = (filters) => {
    return [
      {
        key: "ean",
        dataIndex: "ean",
        title: t("ean"),
        width: t("ean").length * chars,
      },
      {
        key: "mpn",
        dataIndex: "mpn",
        title: t("mpn"),
        width: t("mpn").length * chars,
      },
      {
        key: "brand",
        dataIndex: "brand",
        title: t("brand"),
        width: t("brand").length * chars,
      },
      {
        key: "trademark",
        dataIndex: "trademark",
        title: t("trademark"),
        width: t("trademark").length * chars,
      },
      ...filters
        ?.sort((a, b) => a.orderIndex - b.orderIndex)
        ?.flatMap((groupParam) =>
          groupParam?.parameters
            ?.sort((a, b) => a.orderIndex - b.orderIndex)
            .map((param) => ({
              key: `${groupParam.id}|${param.id}`,
              dataIndex: `${groupParam.id}|${param.id}`,
              title: param.title,
              width: param.title.length * chars,
            })),
        ),
    ];
  };

  const tableData = (products, filters) => {
    return products.map((product) => {
      return {
        key: product.id,
        ean: product.ean,
        mpn: product.mpn,
        brand: product.brand,
        trademark: product.trademark,
        ...tableDataParameterValues(
          product.parameters,
          input.table.columnValues,
          filters,
        ),
      };
    });
  };

  const columnParameterValues = (filters) =>
    filters?.reduce((acc, groupParam) => {
      const parameters = groupParam?.parameters || [];
      for (const param of parameters) {
        const options = param?.options || [];
        acc[`${groupParam.id}|${param.id}`] = {};
        for (const option of options) {
          acc[`${groupParam.id}|${param.id}`][option.id] = option.title;
        }
      }
      return acc;
    }, {}) || {};

  const tableDataParameterValues = (parameters, columnValues, filters) => {
    const parameterValues =
      Object.keys(columnValues || {})?.length > 0
        ? columnValues
        : columnParameterValues(filters);

    return Object.keys(parameters || {}).reduce(
      (acc, key) => ({
        ...acc,
        [key]: parameters[key]
          ?.map((option) => parameterValues[key][option])
          .join(", "),
        ["_parameters"]: parameters,
      }),
      {},
    );
  };

  const onTabChange = (key) => {
    setInput({
      ...input,
      filtersCollapsed: key === PRODUCTS ? input.filtersCollapsed : true,
      activeTab: key,
    });
  };

  const collapseFiltersSidebar = () => {
    setInput({
      ...input,
      filtersCollapsed: !input.filtersCollapsed,
    });
  };

  const collapseColumnsSidebar = () => {
    setInput({
      ...input,
      columnsCollapsed: !input.columnsCollapsed,
    });
  };

  const categoryOnChange = async (value) => {
    if (!value || value?.length === 0) {
      setInput({
        categoryId: null,
        table: {
          loading: false,
          columns: [],
          data: [],
          filters: [],
        },
      });
      return;
    }

    setInput({
      ...input,
      categoryId: value,
      table: {
        ...input.table,
        loading: true,
      },
    });

    const categoryId = value?.at(-1)?.split("|")[1];
    const filters = (await fetchFilters(lang, categoryId))?.items || [];
    const products = await fetchProducts(categoryId, lang);

    const data = tableData(products, filters);

    setInput({
      ...input,
      categoryId: value,
      table: {
        ...input.table,
        columnValues: columnParameterValues(filters),
        columns: products.length > 0 ? columns(filters) : [],
        data: data,
        filteredData: data,
        filters: filters,
        loading: false,
      },
    });
  };

  const applyFilters = (selectedFilters) => {
    const data = input.table.filteredData.filter((product) => {
      return Object.keys(selectedFilters || {}).every((key) => {
        const [groupParameterId, parameterId] = key?.split("|");
        const values =
          product["_parameters"][`${groupParameterId}|${parameterId}`];
        const filterValues = selectedFilters[key];

        return !values?.some((value) => filterValues?.includes(value));
      });
    });

    console.log(data);
  };

  const filterTextOnChange = (groupParameterId, parameterId, values) => {
    const selectedFilters = {
      ...input.table.selectedFilters,
      [`${groupParameterId}|${parameterId}`]: values,
    };

    setInput({
      ...input,
      table: {
        ...input.table,
        selectedFilters: selectedFilters,
      },
    });

    applyFilters(selectedFilters);
  };

  const filterLogicalOnChange = (groupParameterId, parameterId, isChecked) => {
    setInput({
      ...input,
      table: {
        ...input.table,
        selectedFilters: {
          ...input.table.selectedFilters,
          [`${groupParameterId}|${parameterId}`]: isChecked,
        },
      },
    });
  };

  const filterMinNumberOnChange = (groupParameterId, parameterId, value) => {
    console.log(groupParameterId, parameterId, value);
  };

  const filterMaxNumberOnChange = (groupParameterId, parameterId, value) => {
    console.log(groupParameterId, parameterId, value);
  };

  const clearFiltersOnClick = () => {
    setInput({
      ...input,
      table: {
        ...input.table,
        selectedFilters: [],
        filteredData: input.table.data,
      },
    });
  };

  const columnGroupOptionFilterOnChange = (isChecked, groupParameter) => {
    setInput({
      ...input,
      table: {
        ...input.table,
        filteredColumns: {
          ...input.table.filteredColumns,
          [groupParameter?.id]: isChecked
            ? groupParameter?.parameters?.map((parameter) => parameter.id)
            : [],
        },
      },
    });
  };

  const columnOptionFilterOnChange = (groupParameterId, parameterIds) => {
    setInput({
      ...input,
      table: {
        ...input.table,
        filteredColumns: {
          ...input.table.filteredColumns,
          [groupParameterId]: parameterIds,
        },
      },
    });
  };

  return (
    <>
      <Content style={{ overflow: "auto" }}>
        <Card
          bordered={false}
          style={{
            padding: "0 1em",
          }}
        >
          <Cascader
            expandTrigger="hover"
            value={input.categoryId}
            options={input.categories}
            onChange={categoryOnChange}
            placeholder={t("categoryName")}
            showSearch={{
              filter: (input, data) =>
                data.some(
                  (option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >
                    -1,
                ),
            }}
            style={{ width: "100%" }}
          />
        </Card>
        {input.categoryId && (
          <Layout>
            <Sider
              collapsible
              trigger={null}
              breakpoint="lg"
              collapsedWidth={0}
              collapsed={input.filtersCollapsed}
              width={300}
              style={{
                background: "#fff",
                padding: input.filtersCollapsed ? "0" : "2.4em 0 0 2.5em",
                overflow: "auto",
                height: "calc(100vh - 11em)",
                scrollbarWidth: "thin",
                scrollbarGutter: "stable",
              }}
            >
              {!input.filtersCollapsed && (
                <>
                  <Space
                    style={{
                      width: "100%",
                      justifyContent: "flex-end",
                      padding: ".3em 0",
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      type="link"
                      iconPosition="end"
                      onClick={clearFiltersOnClick}
                    >
                      {t("resetFilters")}
                    </Button>
                  </Space>
                  <Collapse
                    items={input.table.filters?.map((groupParameter) => ({
                      key: groupParameter?.id,
                      label: <b>{groupParameter?.title}</b>,
                      children: (
                        <Form layout="vertical">
                          {groupParameter?.parameters?.map((parameter) => (
                            <Form.Item
                              key={`${groupParameter.id}|${parameter?.id}`}
                              label={
                                parameter?.type === "TEXT"
                                  ? parameter?.title
                                  : `${parameter?.title} (${
                                      parameter?.total || 0
                                    })`
                              }
                            >
                              {parameter?.type === "TEXT" && (
                                <Checkbox.Group
                                  value={
                                    input.table.selectedFilters[
                                      `${groupParameter.id}|${parameter.id}`
                                    ]
                                  }
                                  options={parameter?.options?.map(
                                    (option) => ({
                                      value: option.id,
                                      label: `${option.title} (${
                                        option.total || 0
                                      })`,
                                    }),
                                  )}
                                  onChange={(value) =>
                                    filterTextOnChange(
                                      groupParameter.id,
                                      parameter.id,
                                      value,
                                    )
                                  }
                                  style={{ flexDirection: "column" }}
                                />
                              )}
                              {parameter?.type === "LOGICAL" && (
                                <Checkbox
                                  checked={
                                    input.table.selectedFilters[
                                      `${groupParameter.id}|${parameter.id}`
                                    ]
                                  }
                                  onChange={({ taget: { checked } }) =>
                                    filterLogicalOnChange(
                                      groupParameter.id,
                                      parameter.id,
                                      checked,
                                    )
                                  }
                                >
                                  {parameter?.title} ({parameter?.total})
                                </Checkbox>
                              )}
                              {parameter?.type === "NUMBER" && (
                                <Space.Compact style={{ width: "100%" }}>
                                  <InputNumber
                                    value={parameter?.minValue}
                                    onChange={(value) =>
                                      filterMinNumberOnChange(
                                        groupParameter.id,
                                        parameter.id,
                                        value,
                                      )
                                    }
                                    min={parameter?.minValue}
                                    max={parameter?.maxValue}
                                    style={{ width: "50%" }}
                                  />
                                  <InputNumber
                                    value={parameter?.maxValue}
                                    onChange={(value) =>
                                      filterMaxNumberOnChange(
                                        groupParameter.id,
                                        parameter.id,
                                        value,
                                      )
                                    }
                                    min={parameter?.minValue}
                                    max={parameter?.maxValue}
                                    style={{ width: "50%" }}
                                  />
                                </Space.Compact>
                              )}
                            </Form.Item>
                          ))}
                        </Form>
                      ),
                    }))}
                    accordion
                  />
                </>
              )}
            </Sider>
            <Layout style={{ background: "#fff" }}>
              <Card
                bordered={false}
                size="small"
                tabList={[
                  {
                    key: PRODUCTS,
                    tab: t(PRODUCTS),
                  },
                  {
                    key: CHARACTERISTICS,
                    tab: t(CHARACTERISTICS),
                  },
                  {
                    key: ADDITIONAL_INFO,
                    tab: t(ADDITIONAL_INFO),
                  },
                ]}
                activeTabKey={input.activeTab}
                onTabChange={onTabChange}
                style={{
                  padding: "0 2em",
                }}
              >
                {input.activeTab === PRODUCTS && (
                  <>
                    <Space
                      style={{
                        paddingBottom: ".7em",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        onClick={collapseFiltersSidebar}
                        icon={
                          input.filtersCollapsed ? (
                            <MenuUnfoldOutlined />
                          ) : (
                            <MenuFoldOutlined />
                          )
                        }
                        style={{
                          width: 45,
                          height: 32,
                        }}
                      />
                      <Button
                        onClick={collapseColumnsSidebar}
                        icon={
                          input.columnsCollapsed ? (
                            <FilterOutlined />
                          ) : (
                            <FunnelPlotOutlined />
                          )
                        }
                        style={{
                          width: 45,
                          height: 32,
                        }}
                      />
                    </Space>
                    <Table
                      size="middle"
                      columns={input.table.columns?.map((col) => ({
                        ...col,
                        hidden:
                          !["ean", "mpn", "brand", "trademark"].includes(
                            col.key,
                          ) &&
                          !Object.entries(input.table.filteredColumns || {})
                            ?.flatMap(([groupParam, params]) =>
                              params?.map((param) => `${groupParam}|${param}`),
                            )
                            ?.includes(col.key),
                      }))}
                      dataSource={input.table.filteredData}
                      scroll={{ x: "max-content", y: 400 }}
                      pagination={{
                        pageSize: 10,
                      }}
                      loading={input.table.loading}
                      className={"table-striped-rows"}
                    />
                  </>
                )}
              </Card>
            </Layout>
            <Sider
              collapsible
              trigger={null}
              breakpoint="lg"
              collapsedWidth={0}
              collapsed={input.columnsCollapsed}
              width={300}
              style={{
                background: "#fff",
                padding: input.columnsCollapsed ? "0" : "2.4em 2.5em 0 0",
                overflow: "auto",
                height: "calc(100vh - 11em)",
                scrollbarWidth: "thin",
                scrollbarGutter: "stable",
              }}
            >
              {!input.columnsCollapsed && (
                <>
                  <Form layout="vertical">
                    {input.table.filters?.map((groupParameter) => (
                      <div key={groupParameter?.id}>
                        <Form.Item>
                          <Checkbox
                            indeterminate={
                              input?.table.filteredColumns?.[groupParameter?.id]
                                ?.length > 0 &&
                              input?.table.filteredColumns?.[groupParameter?.id]
                                ?.length < groupParameter?.parameters?.length
                            }
                            onChange={({ target: { checked } }) =>
                              columnGroupOptionFilterOnChange(
                                checked,
                                groupParameter,
                              )
                            }
                            checked={
                              groupParameter?.parameters?.length ===
                              input.table.filteredColumns?.[groupParameter?.id]
                                ?.length
                            }
                            style={{ width: "100%" }}
                          >
                            <b>{groupParameter.title}</b>
                          </Checkbox>
                          <Checkbox.Group
                            value={
                              input?.table.filteredColumns?.[
                                groupParameter?.id
                              ] || []
                            }
                            options={groupParameter?.parameters?.map(
                              (parameter) => ({
                                value: parameter.id,
                                label: parameter.title,
                              }),
                            )}
                            style={{
                              flexDirection: "column",
                              padding: "0 0 0 1.7em",
                            }}
                            onChange={(parameterId) =>
                              columnOptionFilterOnChange(
                                groupParameter?.id,
                                parameterId,
                              )
                            }
                          />
                        </Form.Item>
                      </div>
                    ))}
                  </Form>
                </>
              )}
            </Sider>
          </Layout>
        )}
      </Content>
    </>
  );
}

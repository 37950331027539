import { Button, Form, notification, Select, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { fetchRoles } from "src/pages/profile/components/users/components/organization/api/role";
import { fetchTeams } from "src/pages/profile/components/users/components/organization/api/team";
import {
  fetchUsers,
  persistUserRoles,
  persistUserTeam,
} from "src/pages/profile/components/users/components/organization/api/user";
import { usePreferences } from "src/store/globalStore";
import { error, success } from "src/common/message/notification";

export const OrganizationUsers = () => {
  const [t] = useTranslation(["dashboard"]);
  const { language } = usePreferences();
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    users: [],
    userId: null,
    teams: [],
    teamId: null,
    roles: [],
    roleIds: [],
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        roles: ((await fetchRoles(language))?.roles || []).map((role) => ({
          value: role.id,
          label: role.name,
        })),
        teams: ((await fetchTeams())?.teams || []).map((team) => ({
          value: team.id,
          label: team.name,
        })),
        users: ((await fetchUsers())?.users || []).map((user) => ({
          ...user,
          value: user.id,
          label: `${user.name} (${user.email})`,
        })),
      });
    };

    init();
  }, []);

  const onSearch = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const userOnClear = () => {
    setInput({ ...input, userId: null, teamId: null, roleIds: [] });
  };

  const userOnSelect = (value) => {
    const user = input?.users?.find((user) => user.value === value);
    setInput({
      ...input,
      userId: value,
      teamId: user?.teamId,
      roleIds: user?.roleIds,
    });
  };

  const teamOnSelect = async (value) => {
    try {
      setInput({ ...input, teamId: value });
      if (
        input.userId === null ||
        input.userId === undefined ||
        value === null ||
        value === undefined
      ) {
        return;
      }
      await persistUserTeam(input.userId, value);
      success(api, t("success"), t("persistedUserConfiguration"));
    } catch (e) {
      console.log("Persist", e.message);
      error(api, t("error"), t("persistedUserConfiguration"));
    }
  };

  const teamOnClear = async () => {
    try {
      setInput({ ...input, teamId: null });
      await persistUserTeam(input.userId, null);
      success(api, t("success"), t("persistedUserConfiguration"));
    } catch (e) {
      console.log("Persist", e.message);
      error(api, t("error"), t("persistedUserConfiguration"));
    }
  };

  const rolesOnSelect = (value) => {
    setInput({ ...input, roleIds: value });
  };

  const rolesOnClear = () => {
    setInput({ ...input, roleIds: [] });
  };

  const rolesOnSave = async () => {
    try {
      if (
        input.userId === null ||
        input.userId === undefined ||
        input.roleIds === null ||
        input.roleIds === undefined ||
        input.roleIds?.length === 0
      ) {
        return;
      }

      await persistUserRoles(input.userId, input.roleIds);
      success(api, t("success"), t("persistedUserConfiguration"));
    } catch (e) {
      console.log("Persist", e.message);
      error(api, t("error"), t("persistedUserConfiguration"));
    }
  };

  return (
    <Form layout="vertical">
      {contextHolder}
      <Form.Item label={t("users")}>
        <Select
          value={input.userId}
          onChange={userOnSelect}
          options={input.users}
          placeholder={t("user")}
          filterOption={onSearch}
          showSearch
          allowClear={{
            clearIcon: <CloseOutlined onClick={userOnClear} />,
          }}
          style={{ width: "100%" }}
        />
      </Form.Item>
      {input.userId && (
        <>
          <Form.Item label={t("team")}>
            <Select
              value={input.teamId}
              onChange={teamOnSelect}
              options={input.teams}
              placeholder={t("team")}
              filterOption={onSearch}
              showSearch
              allowClear={{
                clearIcon: <CloseOutlined onClick={teamOnClear} />,
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label={t("permissions")}>
            <Space.Compact style={{ width: "100%" }}>
              <Select
                value={input.roleIds}
                onChange={rolesOnSelect}
                options={input.roles}
                placeholder={t("permissions")}
                filterOption={onSearch}
                showSearch
                mode="multiple"
                allowClear={{
                  clearIcon: <CloseOutlined onClick={rolesOnClear} />,
                }}
                style={{ width: "100%" }}
              />
              <Button
                icon={<SaveOutlined />}
                type="primary"
                onClick={rolesOnSave}
              />
            </Space.Compact>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

import { Button, Form, Input, notification, Space } from "antd";
import { MailOutlined, SaveOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { error, success } from "src/common/message/notification";
import {
  fetchOrganization,
  persistOrganization,
} from "src/pages/profile/components/users/api/organization";
import { inviteUser } from "src/pages/profile/components/users/api/userAccess";

export const OrganizationAccount = () => {
  const [t] = useTranslation(["dashboard"]);
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    organization: {
      name: null,
      valid: false,
    },
    invite: {
      email: null,
    },
  });

  useEffect(() => {
    const init = async () => {
      const organization = (await fetchOrganization()) || {};

      setInput({
        ...input,
        organization: {
          ...input.organization,
          name: organization?.error ? null : organization?.name,
          valid: !organization?.error,
        },
      });
    };

    init();
  }, []);

  const organizationNameOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      organization: { ...input.organization, name: value },
    });
  };

  const organizationNameSaveOnClick = async () => {
    try {
      setInput({
        ...input,
        organization: { ...input.organization, valid: false },
      });

      const orgName = input.organization.name?.trim();
      const response = await persistOrganization(orgName);

      if (response?.error) {
        return;
      }

      success(api, t("success"), t("persistedOrganizationName"));
      setInput({
        ...input,
        organization: { ...input.organization, name: orgName, valid: true },
      });
    } catch (e) {
      error(api, t("error"), t("persistedOrganizationName"));
      console.log("Persist", e.message);
    }
  };

  const inviteEmailOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      invite: { ...input.invite, email: value },
    });
  };

  const inviteEmailOnClick = async () => {
    try {
      await inviteUser(input.invite.email);
      success(api, t("success"), t("invitationSent"));
    } catch (e) {
      error(api, t("error"), t("invitationSent"));
      console.log("Persist", e.message);
    }
  };

  return (
    <Form layout="vertical">
      {contextHolder}
      <Form.Item label={t("organizationName")}>
        <Space.Compact style={{ width: "100%" }}>
          <Input
            value={input.organization.name}
            onChange={organizationNameOnChange}
            onPressEnter={organizationNameSaveOnClick}
          />
          <Button
            onClick={organizationNameSaveOnClick}
            type="primary"
            icon={<SaveOutlined />}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("sendInvitation")}>
        <Space.Compact style={{ width: "100%" }}>
          <Input
            value={input.invite.email}
            onChange={inviteEmailOnChange}
            onPressEnter={inviteEmailOnClick}
            disabled={!input.organization.valid}
          />
          <Button
            onClick={inviteEmailOnClick}
            disabled={!input.organization.valid}
            type="primary"
            icon={<MailOutlined />}
          />
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};

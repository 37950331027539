import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchParameters } from "src/pages/catman/components/category/components/paremeterOption/api/parameter";
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  TreeSelect,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { usePreferences } from "src/store/globalStore";
import { error, success } from "src/common/message/notification";
import {
  deleteParameterOption,
  fetchParameterOption,
  persistParameterOption,
} from "src/pages/catman/components/category/components/paremeterOption/api/parameterOption";

export const ParameterOption = () => {
  const { language } = usePreferences();
  const { lang } = useParams();
  const [t] = useTranslation("catman");
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    parameters: [],
    parameterId: null,
    parameterOptions: [],
  });

  useEffect(() => {
    const initInput = async () => {
      const parameters = (await fetchParameters(lang)) || [];

      setInput({
        ...input,
        parameters: parameters.filter((parameter) => parameter.type === "TEXT"),
      });
    };
    initInput();
  }, []);

  const filterTreeNode = (input, node) => {
    return node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const groupParameterOnChange = async (value) => {
    if (!value) {
      setInput({
        ...input,
        parameterId: null,
        parameterOptions: [],
      });

      return;
    }

    const parameterOptions =
      (await fetchParameterOption(value?.split("|")[1])) || [];

    setInput({
      ...input,
      parameterId: value,
      parameterOptions: parameterOptions.map((parameterOption, i) => ({
        ...parameterOption,
        key: i + 1,
        language: lang,
      })),
    });
  };

  const translationOnChange = (key, item) => {
    setInput({
      ...input,
      parameterOptions: input.parameterOptions.map((parameterOption) => ({
        ...parameterOption,
        language: parameterOption.key === key ? item : parameterOption.language,
      })),
    });
  };

  const translationNameInputOnChange = (key, value) => {
    setInput({
      ...input,
      parameterOptions: input.parameterOptions.map((parameterOptions) => {
        if (parameterOptions.key === key) {
          return {
            ...parameterOptions,
            translation: {
              ...parameterOptions.translation,
              [parameterOptions.language]: value,
            },
          };
        }

        return parameterOptions;
      }),
    });
  };

  const deleteParameterOptionOnClick = async (key) => {
    const parameterOption = input.parameterOptions.find((p) => p.key === key);

    if (!!parameterOption?.value) {
      try {
        await deleteParameterOption(parameterOption.value?.split("|")[1]);
        success(api, t("success"), t("deleteParameterOption"));
      } catch (e) {
        error(api, t("error"), t("deleteParameterOption"));
        console.log("Delete", e.message);
        return;
      }
    }

    setInput({
      ...input,
      parameterOptions: input?.parameterOptions?.filter((p) => p.key !== key),
    });
  };

  const saveParameterOptionOnClick = async (key) => {
    const parameterOption = input.parameterOptions.find((p) => p.key === key);
    try {
      await persistParameterOption({
        parameter_id: input.parameterId?.split("|")[1],
        id: parameterOption?.value?.split("|")[1],
        order_index: parameterOption?.order_index,
        translation: parameterOption?.translation,
      });
      success(api, t("success"), t("persistParameterOption"));
    } catch (e) {
      error(api, t("error"), t("persistParameterOption"));
      console.log("Save", e.message);
    }
  };

  const addNewParameterOptionOnClick = () => {
    setInput({
      ...input,
      parameterOptions: [
        ...input.parameterOptions,
        {
          key:
            Math.max(...input.parameterOptions.map((item) => item.key), 0) + 1,
          id: null,
          order_index: null,
          language: language.value,
          translation: {},
        },
      ],
    });
  };

  return (
    <Form layout="vertical">
      {contextHolder}
      <Form.Item label={t("parentParameters")}>
        <Space.Compact style={{ width: "100%" }}>
          <TreeSelect
            treeData={input.parameters}
            value={input.parameterId}
            onChange={groupParameterOnChange}
            filterTreeNode={filterTreeNode}
            placeholder={t("parentParameters")}
            showSearch
            allowClear={{
              clearIcon: <CloseOutlined onClick={groupParameterOnChange} />,
            }}
            treeDefaultExpandAll
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
          />
        </Space.Compact>
      </Form.Item>
      {input.parameterOptions?.map((item) => (
        <div key={item.key}>
          <Divider />
          <Form.Item label={t("parameterName")}>
            <Space.Compact style={{ width: "100%" }}>
              <Select
                value={item.language}
                options={[
                  {
                    value: "de",
                    label: "🇩🇪",
                  },
                  {
                    value: "en",
                    label: "🇺🇸",
                  },
                  {
                    value: "pl",
                    label: "🇵🇱",
                  },
                  {
                    value: "ua",
                    label: "🇺🇦",
                  },
                ]}
                onChange={(value) => translationOnChange(item.key, value)}
                style={{ width: "auto" }}
                virtual
              />
              <Input
                placeholder={t("parameterName")}
                value={item.translation[item.language || language.value]}
                onChange={({ target: { value } }) =>
                  translationNameInputOnChange(item.key, value)
                }
                style={{ width: "100%" }}
              />
              <Button
                onClick={() => deleteParameterOptionOnClick(item.key)}
                icon={<DeleteOutlined />}
                danger
              ></Button>
              <Button
                onClick={() => saveParameterOptionOnClick(item.key)}
                type="primary"
                icon={<SaveOutlined />}
              ></Button>
            </Space.Compact>
          </Form.Item>
        </div>
      ))}
      <Divider />
      <Form.Item>
        <Space.Compact>
          {input.parameterId && (
            <Button
              onClick={addNewParameterOptionOnClick}
              icon={<PlusOutlined />}
            >
              {t("addNew")}
            </Button>
          )}
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};

import { getAuthCookie } from "src/common/api/authentication";

const api = async (method, path, headers, body) => {
  const res = await fetch(`https://requrix.com/api/${path}`, {
    method: method,
    redirect: "follow",
    credentials: "include",
    // mode: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getAuthCookie()}`,
      ...headers,
    },
    body: body !== undefined ? JSON.stringify(body) : null,
  });

  try {
    if (!res.ok) {
      return { error: await res.json() };
    }
    return await res.json();
  } catch (e) {
    console.log("Failed to call API", e);
    return {};
  }
};

export const getAPI = async (path, headers) => {
  return api("GET", path, headers);
};

export const postAPI = async (path, body) => {
  return api("POST", path, null, body);
};

export const deleteAPI = async (path, body) => {
  return api("DELETE", path, null, body);
};

import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { usePreferences } from "src/store/globalStore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCategories } from "src/pages/catman/components/category/components/groupParameters/api/category";
import {
  deleteGroupParameter,
  fetchGroupParameters,
  persistGroupParameters,
} from "./api/groupParameter";
import { error, success } from "src/common/message/notification";

export const GroupParameters = () => {
  const { language } = usePreferences();
  const { lang } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [t] = useTranslation("catman");

  const [input, setInput] = useState({
    id: null,
    options: [],
    language: language.value || "en",
    groupParameters: [],
  });

  useEffect(() => {
    setInput({
      ...input,
      language: language.value || "en",
    });
  }, [language]);

  useEffect(() => {
    const fetchAllCategories = async () => {
      setInput({
        ...input,
        options: (await fetchCategories(lang)) || [],
      });
    };
    fetchAllCategories();
  }, []);

  const traverseTree = (data, source) => {
    return data.map((item) => ({
      ...item,
      disabled: item.type === source,
      children: item?.children ? traverseTree(item?.children, source) : [],
    }));
  };

  const filterTreeNode = (input, node) => {
    return node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const existingCategoryOnChange = async (value) => {
    const groupParameters =
      (await fetchGroupParameters(value.split("|")[1])) || [];

    let i = 0;

    setInput({
      ...input,
      id: value,
      groupParameters: groupParameters.map((groupParameter) => ({
        ...groupParameter,
        language: language.value || "en",
        key: i++,
      })),
    });
  };

  const addNewGroupParameterOnClick = async () => {
    setInput({
      ...input,
      groupParameters: [
        ...input.groupParameters,
        {
          key:
            Math.max(...input.groupParameters.map((item) => item.key), 0) + 1,
          id: null,
          order_index: null,
          language: language.value || "en",
          translation: {},
        },
      ],
    });
  };

  const translationOnChange = (key, item) => {
    setInput({
      ...input,
      groupParameters: input.groupParameters.map((groupParameter) => ({
        ...groupParameter,
        language: groupParameter.key === key ? item : groupParameter.language,
      })),
    });
  };

  const translationNameInputOnChange = (key, value) => {
    setInput({
      ...input,
      groupParameters: input.groupParameters.map((groupParameter) => {
        if (groupParameter.key === key) {
          return {
            ...groupParameter,
            translation: {
              ...groupParameter.translation,
              [groupParameter.language]: value,
            },
          };
        }

        return groupParameter;
      }),
    });
  };

  const deleteGroupOnClick = async (key) => {
    setInput({
      ...input,
      groupParameters: input?.groupParameters?.filter((p) => p.key !== key),
    });
  };

  const groupParametersSaveOnClick = async () => {
    const groupParameters = (input.groupParameters || [])
      .map((groupParameter) => ({
        group_parameter_id: groupParameter.id,
        order_index: groupParameter.order_index || null,
        translation: groupParameter.translation,
      }))
      .filter(
        (groupParameter) =>
          Object.values(groupParameter.translation || {}).filter(
            (param) => param.length > 0,
          ).length > 0,
      );

    try {
      const response =
        (await persistGroupParameters({
          category_id: input.id.split("|")[1],
          group_parameters: groupParameters,
        })) || [];
      let i = 0;

      setInput({
        ...input,
        groupParameters: response.map((groupParameter) => ({
          ...groupParameter,
          language: language.value || "en",
          key: i++,
        })),
      });

      success(api, t("success"), t("persistGroupParameter"));
    } catch (e) {
      error(api, t("error"), t("persistGroupParameter"));
      console.log("Save", e.message);
    }
  };

  const groupParametersDeleteOnClick = async () => {
    try {
      await persistGroupParameters({
        category_id: input.id.split("|")[1],
        group_parameters: [],
      });
      success(api, t("success"), t("deleteGroupParameter"));
    } catch (e) {
      error(api, t("error"), t("deleteGroupParameter"));
      console.log("Delete", e.message);
    }
  };

  return (
    <Form layout="vertical">
      {contextHolder}
      <Form.Item label={t("parentProductCategory")}>
        <Space.Compact style={{ width: "100%" }}>
          <TreeSelect
            treeData={traverseTree(input.options, "NAVIGATION")}
            value={input.id}
            onChange={existingCategoryOnChange}
            filterTreeNode={filterTreeNode}
            placeholder={t("parentProductCategory")}
            showSearch
            allowClear
            treeDefaultExpandAll
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
          />
        </Space.Compact>
      </Form.Item>
      {input.groupParameters?.map((item) => (
        <div key={item.key}>
          <Divider />
          <Form.Item label={t("groupParametersName")}>
            <Space.Compact style={{ width: "100%" }}>
              <Select
                value={item.language}
                options={[
                  {
                    value: "de",
                    label: "🇩🇪",
                  },
                  {
                    value: "en",
                    label: "🇺🇸",
                  },
                  {
                    value: "pl",
                    label: "🇵🇱",
                  },
                  {
                    value: "ua",
                    label: "🇺🇦",
                  },
                ]}
                onChange={(value) => translationOnChange(item.key, value)}
                style={{ width: "auto" }}
                virtual
              />
              <Input
                placeholder={t("groupParametersName")}
                value={item.translation[item.language || language.value]}
                onChange={({ target: { value } }) =>
                  translationNameInputOnChange(item.key, value)
                }
                style={{ width: "100%" }}
              />
              <Button
                onClick={() => deleteGroupOnClick(item.key)}
                icon={<DeleteOutlined />}
                danger
              ></Button>
            </Space.Compact>
          </Form.Item>
        </div>
      ))}
      <Divider />
      <Form.Item>
        <Space.Compact>
          {input.id && (
            <Button
              onClick={addNewGroupParameterOnClick}
              icon={<PlusOutlined />}
            >
              {t("addNew")}
            </Button>
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={groupParametersDeleteOnClick}
          >
            {t("delete")}
          </Button>
          <Button
            icon={<SaveOutlined />}
            type="primary"
            onClick={groupParametersSaveOnClick}
          >
            {t("save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};

import { Button, Form, Input, Select, Space } from "antd";
import { useTranslation } from "react-i18next";
import { SaveOutlined } from "@ant-design/icons";
import { usePreferences } from "src/store/globalStore";
import { useEffect, useState } from "react";
import {
  fetchUserPreference,
  persistUserPreference,
} from "src/pages/profile/components/users/api/userPreference";
import { countries } from "src/components/country";
import { languages } from "src/components/lang";
import { supportedLanguages } from "src/i18n";

export const AccountPreferences = () => {
  const [t] = useTranslation(["dashboard"]);
  const { language, setLanguage } = usePreferences();

  const [input, setInput] = useState({
    profile: {
      name: null,
      country: null,
      language: language,
    },
    countries: countries.map((item) => ({
      value: item.code,
      label: `${item.flag} ${item.name} ${
        item.name === item.country ? "" : `(${item.country})`
      }`,
    })),
    languages: languages
      .filter((language) => supportedLanguages.includes(language.code))
      .map((item) => ({
        value: item.code,
        label: `${item.flag} ${item.language}`,
      })),
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        profile: (await fetchUserPreference()) || {},
      });
    };

    init();
  }, []);

  const nameOnChange = ({ target: { value } }) => {
    setInput({ ...input, profile: { ...input.profile, name: value } });
  };

  const nameSaveOnClick = async () => {
    setInput({
      ...input,
      profile: { ...input.profile, name: input.profile.name },
    });
    await persistUserPreference({ name: input.profile.name });
  };

  const countryOnSelect = async (value) => {
    setInput({ ...input, profile: { ...input.profile, country: value } });
    await persistUserPreference({ country: value });
  };

  const languageOnSelect = async (value) => {
    setInput({ ...input, profile: { ...input.profile, language: value } });
    setLanguage(value);
    await persistUserPreference({ language: value });
  };

  return (
    <Form layout="vertical">
      <Form.Item label={t("name")}>
        <Space.Compact style={{ width: "100%" }}>
          <Input value={input.profile.name} onChange={nameOnChange} />
          <Button
            onClick={nameSaveOnClick}
            type="primary"
            icon={<SaveOutlined />}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("country")}>
        <Select
          value={input.profile.country}
          onChange={countryOnSelect}
          options={input.countries}
          showSearch
          optionFilterProp="label"
          virtual
        />
      </Form.Item>
      <Form.Item label={t("language")}>
        <Select
          value={input.profile.language}
          onChange={languageOnSelect}
          options={input.languages}
          showSearch
          virtual
        />
      </Form.Item>
    </Form>
  );
};

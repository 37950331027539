export const persistCookie = (name, value, period) => {
  if (
    value === undefined ||
    value === null ||
    (typeof value === "string" &&
      value instanceof String &&
      value?.trim() === "")
  ) {
    return;
  }

  document.cookie = `${name}=${value || ""}; expires=${period}; path=/`;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document?.cookie?.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0 && c.length > nameEQ.length) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
};

export const removeCookie = (name) => {
  document.cookie = `${name}=; expires=-99999999;`;
};

export const hasCookieRemoved = (name) => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const hasAuth = cookie.startsWith(`${name}=`);
    const auth = hasAuth ? cookie.split("=")?.[1] : null;
    if (auth === undefined || auth === null || auth === "") {
      return true;
    }
  }

  return false;
};

import { Breadcrumb, Tabs } from "antd";
import React, { useState } from "react";
import "src/pages/catman/components/category/Category.css";
import NavigationSection from "src/pages/catman/components/category/components/navigationSection/NavigationSection";
import ProductCategorySection from "src/pages/catman/components/category/components/productCategorySection/ProductCategorySection";
import { GroupParameters } from "src/pages/catman/components/category/components/groupParameters/GroupParameters";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Parameter from "src/pages/catman/components/category/components/parameter/Parameter";
import { ParameterOption } from "src/pages/catman/components/category/components/paremeterOption/ParameterOption";
import { catmanGroup } from "src/routes/routes";

export default function Category() {
  const [t] = useTranslation("catman");

  const params = useParams();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    navigation: params.subGroup || "navigation",
  });

  const navigationOnChange = (key) => {
    setInput({ ...input, navigation: key });
    navigate(catmanGroup(params) + `/${key}`);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: t("catalog"),
          },
          {
            title: t("category"),
          },
        ]}
      />
      <Tabs
        activeKey={input.navigation}
        onChange={navigationOnChange}
        items={[
          {
            key: "navigation",
            label: t("navigationSection"),
            children: <NavigationSection />,
          },
          {
            key: "product",
            label: t("productCategory"),
            children: <ProductCategorySection />,
          },
          {
            key: "group-parameters",
            label: t("groupParameters"),
            children: <GroupParameters />,
          },
          {
            key: "parameters",
            label: t("parameters"),
            children: <Parameter />,
          },
          {
            key: "parameter-options",
            label: t("parameterOptions"),
            children: <ParameterOption />,
          },
          // {
          //   key: "bulk-add",
          //   label: t("bulkAdd"),
          //   children: <BulkAdd />,
          // },
          // {
          //   key: "category-management",
          //   label: t("categoryManagement"),
          //   children: <CategoryManagement />,
          // },
        ]}
      />
    </>
  );
}

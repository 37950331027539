import React, { createContext, useContext, useReducer } from "react";
import { rootInitialState, rootReducer } from "src/store/reducers/root";
import { preferencesActions } from "src/store/reducers/preferences";
import { ADMIN, USER, USER_MANAGER } from "src/common/api/authorization";

const StoreContext = createContext({
  state: {},
  dispatch: () => {},
});

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    rootReducer,
    null,
    () => rootInitialState,
  );

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);

export const usePreferences = () => {
  const { state, dispatch } = useStore();

  return {
    resetUserPreferences: () => dispatch({ type: preferencesActions.RESET }),
    language: state.preferences.language,
    setLanguage: (language) =>
      dispatch({ type: preferencesActions.LANGUAGE, language }),
    isAuthenticated: state.preferences.roles.includes(USER),
    isAdmin: state.preferences.roles.includes(ADMIN),
    isOrgAdmin: state.preferences.roles.includes(USER_MANAGER),
    setRoles: (roles) => dispatch({ type: preferencesActions.ROLES, roles }),
  };
};

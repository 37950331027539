import { getAPI, postAPI } from "src/common/api/api";

const PATH = "org/admin/organization/team/user";

export const fetchUsers = async () => getAPI(PATH);

export const persistUserTeam = async (user_id, team_id) =>
  postAPI(PATH, { user_id, team_id });

export const persistUserRoles = async (user_id, role_ids) =>
  postAPI(PATH, { user_id, role_ids });

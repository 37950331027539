import { Select } from "antd";
import { usePreferences } from "src/store/globalStore";
import { languages } from "src/components/lang";
import { supportedLanguages } from "src/i18n";

export default function Language() {
  const { language, setLanguage } = usePreferences();

  return (
    <Select
      value={language}
      options={supportedLanguages.map((code) => {
        const lang = languages.find((lang) => lang.code === code);
        return {
          value: code,
          label: `${lang.flag} ${lang.language}`,
        };
      })}
      onChange={setLanguage}
      style={{ width: "140px" }}
    />
  );
}

import { getAuthCookie, hasAuthCookie } from "src/common/api/authentication";

export const ADMIN = "admin";
export const USER = "user";

// Corporate
export const CATALOG_VIEWER = "catalogViewer";
export const CONSOLIDATED_INFO_VIEWER = "consolidatedInfoViewer";
export const DEPARTMENT_REQUEST_RECEIVER = "departmentRequestReceiver";
export const DRAFT_SAVER = "draftSaver";
export const EMPLOYEE_REQUEST_VIEWER = "employeeRequestViewer";
export const PRODUCT_ADDER = "productAdder";
export const PROPOSAL_FORMULATOR = "proposalFormulator";
export const PROPOSAL_REVIEWER = "proposalReviewer";
export const REQUEST_APPROVER = "requestApprover";
export const REQUEST_CREATOR = "requestCreator";
export const REQUEST_DISTRIBUTOR = "requestDistributor";
export const REQUEST_FORWARDER = "requestForwarder";
export const REQUEST_GROUPER = "requestGrouper";
export const REQUEST_HISTORY_VIEWER = "requestHistoryViewer";
export const REQUEST_TRACKER = "requestTracker";
export const SUPPLIER_CONNECTOR = "supplierConnector";
export const TEAM_CREATOR = "teamCreator";
export const USER_MANAGER = "userManager";
export const WINNER_DETERMINER = "winnerDeterminer";

export const getRoles = () => {
  try {
    if (hasAuthCookie) {
      const jwt = getAuthCookie();
      const payload = JSON.parse(atob(jwt.split(".")[1]));

      return payload.scopes || [];
    }

    return [];
  } catch (e) {
    return [];
  }
};

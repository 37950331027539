import {
  Button,
  Form,
  Input,
  notification,
  Segmented,
  Space,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import {
  deleteTrademark,
  fetchTrademarks,
  persistTrademark,
} from "src/pages/catman/components/products/components/trademark/api/trademark";
import { error, success } from "src/common/message/notification";

export const Trademark = () => {
  const NEW = "new";
  const EXISTING = "existing";

  const { t } = useTranslation("catman");
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    status: NEW,
    trademarks: [],
    brandId: null,
    trademarkId: null,
    trademarkName: "",
  });

  const findParent = (array, id) => {
    for (const parent of array) {
      if (parent.children.some((child) => child.value === id)) {
        return parent;
      }
      const found = findParent(parent.children, id);
      if (found) return found;
    }
    return null;
  };

  useEffect(() => {
    const getTrademarks = async () => {
      setInput({
        ...input,
        trademarks:
          (await fetchTrademarks()).map((brand) => ({
            ...brand,
            value: `${brand.value}`,
            children: brand.children.map((trademark) => ({
              ...trademark,
              value: `${brand.value}|${trademark.value}`,
            })),
          })) || [],
      });
    };

    getTrademarks();
  }, []);

  const trademarkStatusOnChange = (value) => {
    setInput({
      ...input,
      status: value,
      brandId: null,
      trademarkName: "",
    });
  };

  const existingTrademarkOnChange = (value) => {
    const parent = findParent(input.trademarks, value);
    setInput({
      ...input,
      brandId: parent?.value,
      trademarkId: value,
      trademarkName: parent?.children?.find(
        (trademark) => trademark.value === value,
      )?.title,
    });
  };

  const newTrademarkOnChange = (value) => {
    setInput({
      ...input,
      brandId: value,
    });
  };

  const trademarkNameOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      trademarkName: value,
    });
  };

  const deleteTrademarkOnClick = async () => {
    if (input.trademarkId !== null) {
      try {
        await deleteTrademark(input.trademarkId.split("|")[1]);
        success(api, t("success"), t("deleteTrademark"));
      } catch (e) {
        error(api, t("error"), t("persistTrademark"));
        console.log("Delete", e.message);
      }
    }

    setInput({
      ...input,
      trademarks: input.trademarks.filter(
        (item) => item.value !== input.trademarkId,
      ),
      brandId: null,
      trademarkName: "",
    });
  };

  const saveTrademarkOnClick = async () => {
    try {
      const request = {
        brand_id: input.brandId,
        id: input.status === NEW ? null : input.trademarkId.split("|")[1],
        name: input.trademarkName,
      };
      const response = await persistTrademark(request);
      const trademarkId = `${response?.brandId}|${response?.id}`;
      success(api, t("success"), t("persistTrademark"));
      setInput({
        ...input,
        trademarks:
          input.status === NEW
            ? input.trademarks.map((brand) =>
                brand.value === input.brandId
                  ? {
                      ...brand,
                      children: [
                        ...brand.children,
                        {
                          value: trademarkId,
                          title: response?.name,
                        },
                      ],
                    }
                  : brand,
              )
            : input.trademarks.map((brand) =>
                brand.value === input.brandId
                  ? {
                      ...brand,
                      children: brand.children.map((child) =>
                        child.value === trademarkId
                          ? {
                              ...child,
                              ...{
                                title: response?.name,
                              },
                            }
                          : child,
                      ),
                    }
                  : brand,
              ),
        trademarkId: trademarkId,
        trademarkName: response?.name,
      });
    } catch (e) {
      error(api, t("error"), t("persistTrademark"));
      console.log("Persist", e.message);
    }
  };

  return (
    <Form layout="vertical">
      {contextHolder}
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Segmented
            value={input.status}
            options={[
              {
                value: NEW,
                label: t(NEW),
              },
              {
                value: EXISTING,
                label: t(EXISTING),
              },
            ]}
            onChange={trademarkStatusOnChange}
          />
          {input.status === NEW && (
            <TreeSelect
              placeholder={t("brandName")}
              value={input.brandId}
              onChange={newTrademarkOnChange}
              treeData={input.trademarks.map((brand) => ({
                ...brand,
                children: brand?.children?.map((trademark) => ({
                  ...trademark,
                  disabled: true,
                })),
              }))}
              showSearch
              allowClear
              treeDefaultExpandAll
              style={{ width: "100%" }}
            />
          )}
          {input.status === EXISTING && (
            <TreeSelect
              placeholder={t("existingTrademark")}
              value={input.trademarkId}
              onChange={existingTrademarkOnChange}
              treeData={input.trademarks.map((brand) => ({
                ...brand,
                disabled: true,
              }))}
              showSearch
              allowClear
              treeDefaultExpandAll
              style={{ width: "100%" }}
            />
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("trademarkName")}>
        <Input value={input.trademarkName} onChange={trademarkNameOnChange} />
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.brandId && input.status === EXISTING && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={deleteTrademarkOnClick}
            >
              {t("delete")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={saveTrademarkOnClick}
          >
            {t("save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};

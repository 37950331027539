import { Breadcrumb, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { catmanGroup } from "src/routes/routes";
import { Brand } from "src/pages/catman/components/products/components/brand/Brand";
import { Product } from "src/pages/catman/components/products/components/product/Product";
import { BulkAdd } from "src/pages/catman/components/products/components/bulkAdd/bulkAdd";
import { ProductManagement } from "src/pages/catman/components/products/components/productManagement/productManagement";
import { Trademark } from "src/pages/catman/components/products/components/trademark/Trademark";

export default function Products() {
  const BRAND = "brandSection";
  const TRADEMARK = "trademarkSection";
  const PRODUCT = "productSection";

  const params = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation("catman");

  const [input, setInput] = useState({
    navigation: params.subGroup,
  });

  useEffect(() => {
    const section = params.subGroup || "brand";
    setInput({ ...input, navigation: section });
    navigate(catmanGroup(params) + `/${section}`);
  }, []);

  const navigationOnChange = (key) => {
    setInput({ ...input, navigation: key });
    navigate(catmanGroup(params) + `/${key}`);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: t("catalog"),
          },
          {
            title: t("product"),
          },
        ]}
      />
      <Tabs
        activeKey={input.navigation}
        onChange={navigationOnChange}
        items={[
          {
            key: "brand",
            label: t(BRAND),
            children: <Brand />,
          },
          {
            key: "trademark",
            label: t(TRADEMARK),
            children: <Trademark />,
          },
          {
            key: "product",
            label: t(PRODUCT),
            children: <Product />,
          },
          {
            key: "bulk-add",
            label: t("bulkAdd"),
            children: <BulkAdd />,
          },
          {
            key: "product-management",
            label: t("productManagement"),
            children: <ProductManagement />,
          },
        ]}
      />
    </>
  );
}

import { Card, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Admin } from "src/pages/profile/components/users/Admin";
import { Account } from "src/pages/profile/components/users/Account";
import { usePreferences } from "src/store/globalStore";
import { OrgAdmin } from "src/pages/profile/components/users/OrgAdmin";
import { useNavigate, useParams } from "react-router-dom";
import { userPreferences } from "src/routes/routes";

export const Profile = () => {
  const [t] = useTranslation(["dashboard"]);
  const { isAdmin, isOrgAdmin } = usePreferences();
  const navigate = useNavigate();
  const params = useParams();

  const [input, setInput] = useState({
    navigation: params.preferences || "profile",
  });

  useEffect(() => {
    setInput({
      ...input,
      navigation: params.preferences || "profile",
    });
  }, [params.preferences]);

  const navigationOnChange = (key) => {
    setInput({ ...input, navigation: key });
    navigate(userPreferences(params) + `/${key}`);
  };

  return (
    <Card
      bordered={false}
      size="small"
      style={{ padding: "0 2.7em", height: "100vh" }}
    >
      <Tabs
        activeKey={input.navigation}
        onChange={navigationOnChange}
        items={[
          {
            key: "profile",
            label: t("profile"),
            children: <Account />,
          },
          isAdmin && {
            key: "admin",
            label: t("admin"),
            children: <Admin />,
          },
          isOrgAdmin && {
            key: "organization",
            label: t("organization"),
            children: <OrgAdmin />,
          },
        ]}
        tabPosition="top"
      />
    </Card>
  );
};

import { Button, Form, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { usePreferences } from "src/store/globalStore";
import { useEffect, useState } from "react";
import { passwordForgot } from "src/pages/auth/login/api/auth";
import { fetchUserPreference } from "src/pages/profile/components/users/api/userPreference";

export const UserAccount = () => {
  const [t] = useTranslation(["dashboard"]);
  const { language, setLanguage } = usePreferences();

  const [input, setInput] = useState({
    profile: {
      organization: null,
      email: null,
    },
    status: {
      changePassword: false,
    },
    countdown: 60,
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        profile: (await fetchUserPreference()) || {},
      });
    };

    init();
  }, []);

  const passwordResetOnClick = async () => {
    if (input.status.changePassword) {
      return;
    }

    setInput({
      ...input,
      status: { ...input.status, changePassword: true },
    });

    await passwordForgot(input.profile.email);

    const interval = setInterval(() => {
      setInput((prev) => {
        if (prev.countdown <= 1) {
          clearInterval(interval);
          return {
            ...prev,
            status: { ...prev.status, changePassword: false },
            countdown: 60,
          };
        }
        return { ...prev, countdown: prev.countdown - 1 };
      });
    }, 1000);
  };

  return (
    <Form layout="vertical">
      {input.profile.organization && (
        <Form.Item label={t("organization")} layout="horizontal">
          <Typography.Text>{input.profile.organization}</Typography.Text>
        </Form.Item>
      )}
      <Form.Item label={t("changePassword")} layout="horizontal">
        <Button
          onClick={passwordResetOnClick}
          type="link"
          style={{ paddingLeft: 0 }}
          loading={input.status.changePassword}
        >
          {input.profile.email}{" "}
          {input.status.changePassword ? `(${t("emailSent")})` : ""}
        </Button>
      </Form.Item>
    </Form>
  );
};
